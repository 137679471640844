import styled from "styled-components"

import { defaultStyles, TooltipWithBounds } from "@visx/tooltip"

import { colorsV2 } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export const Box = styled.div`
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  min-width: 170px;
  font-family: Figtree, sans-serif;
`

export const Header = styled(MText)`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.875rem 1rem;
  background: ${colorsV2.primaryLight};
`

export const Content = styled(MText)`
  background: white;
  padding: 0.875rem 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
`

export const Row = styled.div`
  display: flex;
  gap: ${spacing.M};
`

export const DownArrow = styled.span`
  color: ${colorsV2.systemGood};
`

export const UpArrow = styled.span`
  color: ${colorsV2.systemError};
`

export const Value = styled.span`
  font-weight: bold;
`

const tooltipStyle = {
  ...defaultStyles,
  background: "none",
  boxShadow: "none",
  padding: "0",
}

export const DataPointTooltip = ({
  top,
  left,
  avg,
  min,
  max,
  date,
  unitSymbol,
}: {
  top: number
  left: number
  avg: number | string
  min: number | string
  max: number | string
  date: string
  unitSymbol: string | undefined
}) => {
  return (
    <TooltipWithBounds
      key={Math.random()}
      top={top}
      left={left}
      style={tooltipStyle}
    >
      <Box>
        <Header>{date}</Header>
        <Content>
          <Row>
            Average: <Value>{avg}</Value> {unitSymbol}
          </Row>
          <Row>
            <DownArrow>↓</DownArrow>
            <Value>{min}</Value> {unitSymbol} <UpArrow>↑</UpArrow>
            <Value>{max}</Value> {unitSymbol}
          </Row>
        </Content>
      </Box>
    </TooltipWithBounds>
  )
}
